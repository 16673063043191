let lazyScriptCounter = 0
const body = document.querySelector('body')
// eslint-disable-next-line no-use-before-define
document.addEventListener('click', lazyloading)
// eslint-disable-next-line no-use-before-define
document.addEventListener('mousemove', lazyloading)
// eslint-disable-next-line no-use-before-define
document.addEventListener('scroll', lazyloading)

const resetVersion = `?v=${Date.now()}`
// eslint-disable-next-line no-useless-concat
// const resetVersion = '?ver=' + '1.0.0'

function lazyloading() {
  if (lazyScriptCounter === 0) {
    const scripts = [
      '/wp-includes/js/jquery/jquery.js',
      // `/wp-content/plugins/xcritical/public/js/xcritical-public.js${resetVersion}`,
      '/wp-content/themes/airmarkets/assets/js/news-pages.js',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < scripts.length; i++) {
      const script = document.createElement('script')
      script.src = scripts[i]
      script.async = false
      document.body.append(script)
    }
    // eslint-disable-next-line no-plusplus
    lazyScriptCounter++

    // eslint-disable-next-line no-use-before-define
    createPageScript('.accordion-section', '/wp-content/themes/airmarkets/assets/js/inner.js')

    // eslint-disable-next-line no-use-before-define
  }
}

function createPageScript(selectorItem, srcScript) {
  const scripts = [
    {
      selector: selectorItem,
      src: srcScript,
    },
  ]

  scripts.forEach(function (item) {
    if (item.selector.length === 0 || document.querySelector(item.selector) !== null) {
      const script = document.createElement('script')
      script.src = item.src
      script.async = false
      document.body.append(script)
    }
  })
}
